::selection {
    background-color: #333333;
  }

  .box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #F2F2F2;
    .ant-select-item {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 45px;
      padding-left: 66px;
    }

  }


  .hearder {
    width: 100%;
    height: 674px - (674px * 0.25);;
    background: url(../assets/background.png);
    background-size: 100% 100%;
    display: flex;
    // position: relative;
    background-repeat: no-repeat;
    flex-direction: column;
    align-items: center;

    .bigModal {
      height: 68px - (68px * 0.25);
      font-size: 60px - (60px * 0.25);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #29223A;
      margin: 106.5px 0 17px 0;
    }

    .price {
      // height: 76px;
      display: flex;

      .pricespan {
        font-size: 27px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #29223A;
        line-height: 20px;
        height: 28px - (28px * 0.25);
        margin-top: 53px;
      }
    }
  }

  .eighty {
    width: 140px - (140px * 0.25);
    height: 140px - (140px * 0.25);
  }

  .tokens {
    width: 500px - (500px * 0.25);
    height: 140px - (140px * 0.25);
  }

  .economize {
    font-size: 27px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #29223A;
    // padding-bottom: 20px;
    height: 72px;
    width: 54px;
    line-height: 137px;
  }

  .blueeconomize {
    font-size: 27px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2E67D1;
  }

  .lowas {
    font-size: 45px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #29223A;
    margin-top: 10px;
    height: 50px;
  }

  .pricenum {
    font-size: 58px;
    font-family: "Barlow", sans-serif;
    font-weight: bold;
    color: #2E67D1;
    margin: 0 13px 37px 8px;
  }

  .logo {
    position: absolute;
    left: 32px;
    top: 27px;
  }

  .flexModal {
    display: flex;
    width: 1200px;
  }

  .experience {
    width: calc((100% - 64px) / 3);
    background: #FFFFFF;
    height: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .flexModal {
    margin: 32px 149px;
    display: flex;
    justify-content: space-between;
  }

  .modalName {
    font-size: 44px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #333330;
  }

  .btn {
    margin-top: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 76px;
    background: #2E67D1;
    border-radius: 6px;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    cursor: pointer;
  }

  .middle {
    // width: calc(100% - 155px -155px);
    min-height: 707px;
    background: #ffffff;
    margin: 0 149px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1200px;

    .title {
      font-size: 27px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding-top: 35px;
      padding-bottom: 81px;
    }

    .btns {
      width: 532px;
      height: 110px;
      background: #2E67D1;
      border-radius: 8px;
      border: 2px solid #FFE6C2;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 30px;
    }

    .money {
      font-size: 80px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2E67D1;
      margin-right: 33px;
      padding-bottom: 12px;
    }

    .cost {
      font-size: 37px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }

  }

  .ant-select-item {
    background: #ffffff;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #2E67D1;
    color: #FFFFFF;
  }


  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #2E67D1;
    color: #FFFFFF !important;
  }

  .ant-select-dropdown {
    padding: 0 !important;
  }

  .ant-form-item-label>label {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;

  }

  .footera {
    // width: calc(100% - 149px -149px);
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    background: #FFFFFF;
    margin: 31px 149px 36px 149px;
    width: 1200px;
  }

  .select {
    display: flex;
    align-items: flex-start;
    font-size: 25px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin-left: 178px;
    height: 100px;
  }

  .modalStyle {
    .ant-modal-close-x {
      display: none;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }
  }

  .modalTitle {
    justify-content: center;
    margin-bottom: 20px;

    span {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-bottom: 20px;
    }

    div {
      width: 20px;
      height: 4px;
      background: #FFE6C2;
    }
  }

  .ant-input {
    border: none;
    height: 55px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    padding: 0;
    border-bottom: 1px solid #E0E0E0;
  }

  .formFooter {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }

  .cancel,
  .submit {
    width: 220px;
    height: 50px;
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .formButton {
    display: flex;
    margin-top: 30px;
  }

  .submit {
    background: #2E67D1;
    color: #FFFFFF;
    border: 1px solid #2E67D1;

    &:hover {
      border-color: #000000;
      color: #000000;
    }
  }

  .cancel {
    margin-right: 20px;
    border: 1px solid #000000;

    &:hover {
      border-color: #000000;
      color: #000000;
    }
  }

  .ant-modal-body {
    padding: 40px 40px 36px 40px;
  }

  .ant-input:focus {
    box-shadow: none;
    border-color: initial;
  }

  .ant-input:hover {
    box-shadow: none;
    border-color: initial;
  }

  .SuccessfulPop-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .characters {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .img {
    margin: 40px 0 20px 0;
  }

  .cancelbtn {
    width: 140px;
    height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 43px;
  }

  .SelectModel {
    display: flex;
    margin-bottom: 96px;
    margin-top: 3px;

    .ant-select-selection-item {
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2E67D1 !important;
      height: 50px;
      margin-left: 24px;
      margin-top: 3px;
    }

    .ant-select {
      border-bottom: 1px solid #333333;
      margin-top: 8px;
    }

    .ant-select-arrow {
      color: #2E67D1;
      right: 0;
    }



  }
  .ant-select-item {
    font-size: 28px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 45px !important;
    padding-left: 66px !important;
  }
  .ant-select-selector {
    width: 348px;
  }

  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.88);
    background-color: #2E67D1 !important;
  }

  .ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: #FFFFFF;
    background-color: #2E67D1 !important;
  }

  .popupClassName {
    margin-top: 12px !important;
  }


  .ModalInput {
    border: none;
  }
  .mock-block {
    margin-right: 36px;
    margin-top: -5px;

    span {
      border-bottom: 1px solid #333333;
    }

    .ant-input {
      width: 120px;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2E67D1;
      padding-left: 15px;
      margin-right: 15px;
    }
  }

  .input {
    // border-bottom: 1px solid #333333;
    border: none;
  }

  .input::-webkit-inner-spin-button,
  .input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .drawer {

    // position: relative;
    .ant-drawer-wrapper-body {
      background: #FFFFFF;
    }

    .ant-drawer-header {
      // height: 190px !important;
      flex: none;
      border-bottom: none;
    }

    .ant-drawer-title {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333330;
      padding-top: 45px;
    }

    .footers {
      display: flex;
      margin-top: 31px;
      align-items: center;
      justify-content: center;

      .ant-input {
        width: 658px;
        height: 86px;
        background: rgba(216, 216, 216, 0);
        box-shadow: inset 0px 1px 3px 0px #202020;
        border-radius: 4px;
        border: 1px solid #979797;
        font-size: 27px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #D5D5D5;
        margin-right: 38px;
        padding-left: 17px;
      }

      div {
        width: 91px;
        height: 89px;
        background: linear-gradient(135deg, #4A4A4A 0%, #454545 100%);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .icon {
    position: absolute;
    right: 39px;
    top: 38px;
    cursor: pointer;
  }

  .content {
    width: calc(100%);
    height: calc(100% - 178px);
    background: #121011;
    border-radius: 4px;
    border: 1px solid #4B4B4B;
    position: relative;

    span {
      border-top-left-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #D5D5D5;
      width: 108px;
      height: 35px;
      border: 1px solid #4B4B4B;
      display: flex;
      cursor: pointer;

      img {
        display: block;
        padding: 13px 5px 9px 8px;
      }

      div {
        padding-top: 4px;
      }
    }
  }

  .addChat {
    position: absolute;
    right: 29px;
    top: 111px;
    width: 103px;
    height: 35px;
    background: #000000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .chat {
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFE6C2;
      padding-left: 7px;
    }
  }

  .disabledLabel {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    padding-bottom: 10px;
  }

  .cancelimg {
    position: absolute;
    right: 32px;
    top: 20px;
    cursor: pointer;
  }

  .save {
    font-size: 37px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    padding-bottom: 12px;

    span {
      color: #2E67D1;
    }
  }

  .addL {
    position: absolute;
    right: 23px;
    top: 58px;
    width: 81px;
    height: 28px;
    background: #E5E5E5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 13px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2E67D1;
    }
  }

  .compare {
    display: flex;
  }

  .column {
    width: 63px;
    height: 72px;
    background: #2E67D1;
    position: absolute;
    left: 73px;
    bottom: 0px;
  }

  .colorcolumn {
    width: 63px;
    height: 242px;
    background: #D8D5E4;
    position: absolute;
    left: 204px;
    bottom: 0;
  }

  .jmName {
    font-size: 30px;
    font-family: "MyCustomFont";
    font-weight: 500;
    color: #2E67D1;
    position: absolute;
    left: 74px;
    bottom: -62px;
  }

  .business {
    font-size: 30px;
    font-family: "MyCustomFont";
    font-weight: 500;
    color: #B8B7BD;
    position: absolute;
    left: 177px;
    bottom: -62px;
  }

  .chart {
    position: relative;
    height: 410px - (410px * 0.25);
    width: 467px - (467px * 0.25);
    border-bottom: 2px solid #979797;
    margin-left: 73px - (73px * 0.25);
    margin-top: 143px - (143px * 0.25);
  }

  .numgolden {
    position: absolute;
    left: 42px;
    top: 168px;
    font-size: 38px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #2E67D1;
    // z-index: 1000
  }

  .num {
    position: absolute;
    left: 183px;
    top: -3px;
    font-size: 38px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #CECBD9;
    z-index: 1000;
  }

  .ant-drawer-close {
    display: none;
  }

  .cancelimg {
    position: absolute;
    right: 21px;
    top: 14px;
  }

@primary-color: #FA6400;