$rootFontSize: 16px; // 设置根元素的字体大小

@function rem($pixels) {
    @return $pixels / $rootFontSize * 1rem;
}

/* 阻止Safari浏览器的缩放行为 */
@supports (-webkit-touch-callout: none) {

    .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-focused.ant-select-multiple .ant-select-selector,
    .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
        font-size: 12px; // 或适当的字体大小
    }
}

body,
html {
    min-width: rem(300px);
}

.boxMobile {
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    background: #F2F2F2;
}

.header {
    width: 100%;
    height: rem(190px);
    background: url('../assets/banner.jpg');
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.moblieLogo {
    position: absolute;
    left: rem(8px);
    top: rem(13px);
}

.giantGate {
    padding-top: rem(49px);
    padding-left: rem(6px);
}

.main {
    margin: rem(11px) rem(10px) 0px rem(10px);

    .toChart {
        width: 100%;
        height: rem(160px);
        background: #ffffff;
        margin-bottom: rem((10px));
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            font-size: rem(24px);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            margin-top: rem(24px);
            margin-bottom: rem(19px);
        }

        div {
            width: rem(210px);
            height: rem(50px);
            background: #2E67D1;
            border-radius: rem(6px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: rem(16px);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}

.count {
    // height: rem(514px);
    background: #FFFFFF;
    margin-left: rem(10px);
    margin-right: rem(10px);
    margin-bottom: rem(10px);
    display: flex;
    // justify-content: center;

    .countBox {
        padding: 0px rem(14px);
        width: 100%;

        .calculator {
            font-size: rem(20px);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            display: flex;
            justify-content: center;
            margin-top: rem(51px);
            margin-bottom: rem(52px);
        }

        .btns {
            width: rem(210px);
            height: rem(50px);
            background: #2E67D1;
            border-radius: rem(6px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: rem(16px);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }

        .money {
            font-size: rem(18px);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            justify-content: flex-end;
            align-items: end;
            padding-bottom: rem(19px);
            padding-top: rem(29px);

            .amount {
                font-size: rem(30px);
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #2E67D1;
                line-height: rem(28px);
            }

        }

        .save {
            font-size: rem(18px);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            justify-content: flex-end;
            padding-bottom: 42px;
        }
    }
}

.footer {
    margin: 0px rem(11px) rem(11px) rem(11px);
    height: rem(64px);
    background: #FFFFFF;
    font-size: rem(14px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
}


.modal {
    width: 100%;
    max-width: rem(500px);
    // height: rem(202px);
    border-top: 1px solid #F3f3f3;
    border-bottom: 1px solid #F3f3f3
}

.btns {
    width: rem(210px);
    height: rem(50px);
    background: #2E67D1;
    border-radius: rem(6px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(16px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
}

.btnDiv {
    display: flex;
    justify-content: center;
    padding-bottom: rem(17px);
    z-index: 10000;
}

.grounds {
    width: 100%;
    height: 1px;
}

.modalStyle {
    // background: #F3f3f3;

    .ant-modal-close-x {
        display: none;
    }

    .ant-form-item {
        margin-bottom: rem(12px);
    }

    .ant-modal-body {
        padding: 0 !important;
    }
}

.modalTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: rem(20px);

    span {
        justify-content: center;
        font-size: rem(20px);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        // margin-bottom: rem(30px);
        margin: 0 auto rem(20px);
    }

    div {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: 20px;
        height: 4px;
        background: rgba(131, 90, 226, 0.44);
    }
}


// .selectDownStyle {
//     width: rem(200px) !important;
//     height: rem(200px) !important;
// }

.ant-form-item-control-input-content {
    border-bottom: 1px solid #f3f3f3
}

.formFooter {
    font-size: rem(16px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
}

.cancel,
.submit {
    width: rem(220px);
    height: rem(50px);
    border-radius: rem(6px);
    font-size: rem(16px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    border-radius: rem(6px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.formButton {
    display: flex;
    margin-top: rem(30px);
}

.submit {
    background: #2E67D1;
    color: #FFFFFF;
    border: 1px solid #2E67D1;

    &:hover {
        border-color: #000000;
        color: #000000;
    }
}

.cancel {
    margin-right: rem(20px);
    border: 1px solid #000000;

    &:hover {
        border-color: #000000;
        color: #000000;
    }
}

// .ant-modal-body {
//     padding: rem(40px) rem(40px) rem(46px) rem(40px);
// }

.ant-input:focus {
    box-shadow: none;
    border-color: initial;
}

.ant-input:hover {
    box-shadow: none;
    border-color: initial;
}

.SuccessfulPop-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.characters {
    font-size: rem(18px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
}

//   .img {
//     margin: 40px 0 20px 0;
//   }

.cancelbtn {
    width: rem(140px);
    height: rem(50px);
    font-size: rem(16px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-top: rem(20px);
    margin-bottom: rem(43px);
}

.formitem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: rem(22px);
    height: rem(50px);
    border-bottom: 1px solid #f3f3f3;
    position: relative;

    .ant-select-selector {
        width: rem(456px);
    }

    .divs {
        margin-top: rem(16px);
    }

    .ant-select {
        max-width: rem(200px);
        min-width: rem(200px);
        margin-top: rem(11px);
    }

    .ant-select-item {
        padding: 0 !important;
    }

    .ant-select-item .ant-select-item-option .ant-select-item-option-active {
        padding: 0 !important;
    }
}

.selectDown {
    .ant-select-item {
        padding: 0 !important;
    }
}

.drawer {

    // position: relative;
    .ant-drawer-wrapper-body {
        background: #FFFFFF;
    }

    .ant-drawer-header {
        // height: 190px !important;
        flex: none;
        border-bottom: none;
    }

    .ant-drawer-title {
        font-size: rem(28px);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333330;
        padding-top: rem(35px);
    }

    .footers {
        display: flex;
        margin-top: rem(31px);
        align-items: center;
        justify-content: center;

        .ant-input {
            width: rem(658px);
            height: rem(86px);
            background: rgba(216, 216, 216, 0);
            box-shadow: inset 0px 1px 3px 0px #202020;
            border-radius: rem(4px);
            border: 1px solid #979797;
            font-size: rem(27px);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #D5D5D5;
            margin-right: rem(38px);
            padding-left: rem(17px);
        }

        div {
            width: rem(91px);
            height: rem(89px);
            background: linear-gradient(135deg, #4A4A4A 0%, #454545 100%);
            border-radius: rem(4px);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.cancelimg {
    position: absolute;
    right: rem(32px);
    top: rem(20px);
    cursor: pointer;
}

.addL {
    position: absolute;
    right: rem(23px);
    top: rem(58px);
    width: rem(81px);
    height: rem(28px);
    background: #E5E5E5;
    border-radius: rem(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        font-size: rem(13px);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2E67D1;
    }
}

.antinput {
    max-width: rem(200px);
    margin-top: rem(9px);
    font-size: rem(18px);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2E67D1;
    border: none;
    height: rem(35px);
    font-size: rem(16px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    padding: 0;

}


.ant-select-selector {
    padding: 0 !important;
}

.times {
    position: absolute;
    top: rem(14px);
    right: 0;
}

.formitems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contents {
    display: flex;
    justify-content: center;
    width: 100%;
    line-height: rem(40px);
    font-size: rem(18px);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: rem(18px);
    padding-bottom: rem(5px);
    border-bottom: 1px solid #f3f3f3;
}

.ant-select-arrow {
    // margin-left: rem(12px) !important;
    color: #ffffff !important;
}

.ant-select-selection-item {
    font-size: rem(18px);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2E67D1;
}

.icons {
    position: absolute;
    right: 0;
    top: rem(23px);
    width: 12px;
    height: 6px;
    color: #2E67D1;
}